<template>
  <div class="form-group" :style="cssVars">
    <h3 v-if="!!fieldTitle" class="form-input-title">{{ fieldTitle }}</h3>

    <label :for="`${fieldName}`" class="check-label label-checkbox">
      <span class="option-text"><span v-html="text"></span> <slot name="additional-text"></slot></span>

      <input :id="`${fieldName}`"
             v-model="input"
             :name="`${fieldName}`"
             :value="value"
             type="checkbox"
             class="input-check"
             @change="emitValue(input)">
      <span class="check-mark"></span>
    </label>


    <div v-show="apiError || errorMessages.length > 0">
      <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
      <p v-if="apiError" class="text-error">{{ apiError }}</p>
      <p v-for="(error, index) of errorMessages" v-else :key="index" class="text-error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
// import {ref, watch} from '@vue/composition-api';
import Validator from '@/utils/yo-validator/YoValidator'

export default {
  name: 'Checkbox',
  props: {
    // required
    fieldTitle: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    // not required
    rules: {
      type: String,
      required: false,
      default: ''
    },
    bindValue: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    apiErrorText: {
      type: String,
      required: false,
      default: ''
    },
    callback: {
      type: Function,
      required: false,
      default: () => {}
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    checkmarkColor: {
      type: String,
      required: false,
      default: 'var(--pink_main)'
    }
  },
  setup(props) {
    /** validator returned error messages **/
    const { errorMessages, setFieldData } = Validator.register(props)

    /** watch api error text from parent **/
    const apiError = ref('')
    watch(() => props.apiErrorText, (newVal) => {
      apiError.value = newVal.trim()
    })

    const cssVars = computed(() => { return { '--checkmark-color': props.checkmarkColor } })

    /** watch input in the template **/
    const input = ref(props.value)

    // set data to form wrapper after debounce
    const emitValue = (option) => {
      // if callback is passed, it means the radio is not a form value, it's just a helper to trigger another action
      if (props.callback) {
        props.callback(option.value)
      }
      // if the bind value is true, bind the value to the form data
      if (props.bindValue) {
        // set the value here since the v-model is fired sometimes after setFieldData
        setFieldData(input.value)
      }
    };

    /** on created **/
    (function setValueOnCreate() {
      if (props.value) {
        input.value = props.value
        setFieldData(props.value)
      }
    })()

    return {
      errorMessages,
      input,
      apiError,
      emitValue,
      cssVars
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.functions";
@import "~@/assets/css/base.mixins.scss";
@import "~@/assets/css/base.variables.scss";

.label-checkbox {
  margin-left: rem(15);
}

.check-label {
  margin: 0;
  padding: rem(5) 0 rem(5) rem(45);
  display: block;
  position: relative;
  cursor: pointer;
  text-align: left;
  @include user-select(none);

  &:checked {
    color: var(--checkmark-color);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .check-mark {
      background-color: var(--checkmark-color);
      border: none;

      &:after {
        display: block;
      }
    }
  }

  .check-mark {
    @include position(absolute, $top: 0, $left: 0);
    @include border-radius(rem(8));
    height: rem(28);
    width: rem(28);
    background-color: white;
    box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);

    &:after {
      @include position(absolute, $top: rem(3), $left: rem(10));
      @include rotate(45deg);
      content: "";
      display: none;
      border: solid white;
      border-width: 0 rem(2) rem(2) 0;
      height: rem(16);
      width: rem(9);
    }
  }

  .check-mark-error {
    @extend .check-mark;
  }
}

.check-label-round {
  @extend .check-label;

  .check-mark {
    @include border-radius(50%);
  }
}

.input-check{
  width: rem(0);
}
</style>
